<template>
  <ib-dialog
    :visible="dialogAddCoverageVisible"
    :fullscreen="true"
    @close="closeDialogAddCoverage"
  >
    <ib-dialog-container>

      <!-- Header -->
      <header class="header-flex">
        <div>
          <h2>{{ $t('pages.businessGuide.market.addTypes.marketCoverage.title') }}</h2>
          <p class="mt-1 o-50 text-regular">
            {{ $t('pages.businessGuide.market.addTypes.marketCoverage.description') }}
          </p>
        </div>
      </header>
      <!-- /Header -->

      <!-- Market Types -->
      <el-row type="flex" class="flex-column flex-md-row pb-4" :gutter="20">
        <el-col
          v-for="marketType in marketTypes"
          :key="marketType.coverageElementId"
          :md="6"
          class="d-md-flex mb-3"
        >
          <div class="coverage-card" @click="selectMarketType( marketType.coverageElementId )">
            <img :src="require(`@/assets/img/icons/story-mode/market/${marketType.image}`)">
            <h3> {{ marketType.title }} </h3>
            <p v-html="marketType.text" />
          </div>
        </el-col>
      </el-row>
      <!-- /Market Types -->

    </ib-dialog-container>
  </ib-dialog>
  <!-- /El Dialog -->
</template>

<script>
export default {
  name: 'DialogAddCoverage',
  props: {
    dialogAddCoverageVisible: Boolean
  },
  data () {
    return {
      dialogVisible: false,
      guideDialogVisible: false,
      expandedDialogGuide: true,
      productName: '',
      productDesc: '',
      marketTypes: [
        {
          coverageElementId: 1,
          title: this.$t('pages.businessGuide.market.addTypes.marketCoverage.localMarket.title'),
          text: this.$t('pages.businessGuide.market.addTypes.marketCoverage.localMarket.text'),
          image: 'local-market-icon.svg'
        },
        {
          coverageElementId: 2,
          title: this.$t('pages.businessGuide.market.addTypes.marketCoverage.regionalMarket.title'),
          text: this.$t('pages.businessGuide.market.addTypes.marketCoverage.regionalMarket.text'),
          image: 'regional-market-icon.svg'
        },
        {
          coverageElementId: 3,
          title: this.$t('pages.businessGuide.market.addTypes.marketCoverage.nationalMarket.title'),
          text: this.$t('pages.businessGuide.market.addTypes.marketCoverage.nationalMarket.text'),
          image: 'national-market-icon.svg'
        },
        {
          coverageElementId: 4,
          title: this.$t('pages.businessGuide.market.addTypes.marketCoverage.internationalMarket.title'),
          text: this.$t('pages.businessGuide.market.addTypes.marketCoverage.internationalMarket.text'),
          image: 'international-market-icon.svg'
        }
      ]
    }
  },
  methods: {
    toggleDialogGuide () {
      this.guideDialogVisible = !this.guideDialogVisible
    },

    selectMarketType (marketTypeIndex) {
      let marketTypeSelected = {}
      this.marketTypes.forEach((marketType) => {
        if (marketType.coverageElementId === marketTypeIndex) {
          marketType.ideaId = this.$store.state.idea.id
          marketTypeSelected = marketType
          this.$store.commit('idea/setCoverageElement', { coverageElement: marketTypeSelected })
          this.$emit('close-dialog-add-coverage')
        }
      })
      this.$http.post('story-mode/research/market/select-coverage', marketTypeSelected)
    },

    closeDialogAddCoverage () {
      this.$emit('close-dialog-add-coverage')
    }
  }
}
</script>
